@import "@cof/gravity-core/scss/grv-core-components.scss";

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

c1-privacy-footer {
  flex-shrink: 0;
}

main {
  display: block;
}

.card-wrapper {
  .request-wrapper {
    // styling hr tag in children
    hr {
      margin: 0 18px;
    }
    // grey color for filled clock icon
    .requestID {
      svg {
        fill: #7d7d7d;
      }
    }
  }
}

c1-privacy-download-ready-data {
  .grv-row {
    margin-top: 4px;
    margin-left: 10px;
  }
  .status {
    padding: 0px;
  }
}

.sub {
  cx-placeholder {
    margin-top: 12px;
  }
}
